import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import FunctionsPageTemplate from '../components/Functions/FunctionsPageTemplate';
import MainSection from '../components/Functions/MainSection';
import main from '../assets/images/functions/proxy/main.png';
import main2x from '../assets/images/functions/proxy/main_2x.png';
import headLeft from '../assets/images/functions/proxy/head_left.png';
import headRight from '../assets/images/functions/proxy/head_right.png';
import HeadSection from '../components/Functions/HeadSection';
import PossibilitiesSection from '../components/Functions/PossibilitiesSection';
import first from '../assets/images/functions/proxy/1.png';
import first2x from '../assets/images/functions/proxy/1_2x.png';
import second from '../assets/images/functions/proxy/2.png';
import second2x from '../assets/images/functions/proxy/2_2x.png';
import third from '../assets/images/functions/proxy/3.png';
import third2x from '../assets/images/functions/proxy/3_2x.png';
import fourth from '../assets/images/functions/proxy/4.png';
import fourth2x from '../assets/images/functions/proxy/4_2x.png';
import fifth from '../assets/images/functions/proxy/5.png';
import fifth2x from '../assets/images/functions/proxy/5_2x.png';
import ImageContainer from '../components/ImageContainer/ImageContainer';
import BlueSpan from '../components/common/typography/BlueSpan';

const faqs = [
  {
    question: 'What’s a proxy manager?',
    answer:
      'Proxy manager is a place where you can save your proxies in a separate spreadsheet and use them as needed, choosing them from the list.',
  },
  {
    question: 'Can I buy a proxy from you?',
    answer: 'No, but we support third party proxy providers.',
  },
  {
    question: 'What’s the difference between temporary and saved proxies?',
    answer:
      'Temporary proxies are added to the Connection setting of the profile, but they cannot be saved and subsequently chosen from the list or the Proxy tab. Saved proxies are kept in the Available list.',
  },
].map((item) => ({
  question: <Trans>{item.question}</Trans>,
  answer: <Trans>{item.answer}</Trans>,
}));

const cardsInfo = [
  {
    title: 'Built-in proxy checker',
    description:
      'Check the quality of your proxy connection right in Octo Browser.',
    icon: <ImageContainer src={first} srcSet={{ _2x: first2x }} />,
  },
  {
    title: 'Saving and editing',
    description:
      'Work with entire profile groups while mass-adding proxies. All proxies added to the proxy manager are saved and available for editing.',
    icon: <ImageContainer src={second} srcSet={{ _2x: second2x }} />,
  },
  {
    title: 'Proxy AutoCompleting',
    description: 'Use a template to automatically add a proxy to a profile.',
    icon: <ImageContainer src={third} srcSet={{ _2x: third2x }} />,
  },
  {
    title: 'Unlimited number of temporary proxies',
    description:
      'Add the necessary number of temporary proxies for a profile while creating or editing it.',
    icon: <ImageContainer src={fourth} srcSet={{ _2x: fourth2x }} />,
  },
  {
    title: 'Teamwork',
    description: 'No-limit proxy sharing within a team.',
    icon: <ImageContainer src={fifth} srcSet={{ _2x: fifth2x }} />,
  },
];

const ProxyPage = (): JSX.Element => (
  <FunctionsPageTemplate faq={faqs}>
    <HeadSection
      description="Octo Browser supports all popular proxy types (https, socks5, ssh), and allows using third-party IP address management tools, and reusing the proxy in the future."
      leftBgImage={headLeft}
      rightBgImage={headRight}
      title="Proxy"
    />
    <MainSection
      description="Octo Browser has a robust interface for connecting your profiles with proxies. You can mass import and save proxies, check their status, and automate your proxy tasks using API."
      image={main}
      image2x={main2x}
      title={
        <Trans i18nKey="Connect your proxies">
          Connect your <BlueSpan>proxies</BlueSpan>
        </Trans>
      }
    />
    <PossibilitiesSection
      cards={cardsInfo}
      title={
        <Trans i18nKey="Proxy features">
          Proxy <BlueSpan>features</BlueSpan>
        </Trans>
      }
    />
  </FunctionsPageTemplate>
);

export default ProxyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["proxyPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
